import { API_URL } from "@/constants/env";
import { SaleConsumer } from "@/models/interfaces/commercial/saleConsumer.interface";
import axios from "axios";

import { prepareData } from "./preparedata.service";
import { newInvoice } from "./newinvoice.service";
import store from "@/store";

export const insert = async (entity: SaleConsumer): Promise<SaleConsumer[]> => {
  store.state.loading.loading.value = true;

  const r = (await axios.post(`${API_URL}/venda/Salvar`, prepareData(entity)))
    .data;
  entity.Id = r.Id;
  console.log("new invoice 00", entity.Id);
  await newInvoice(entity);
  console.log("new invoice 07", entity.Id);

  return r;
};
